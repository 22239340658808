<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-autocomplete
          :items="listado_instituciones"
          v-model="institucion"
          item-text="nombre"
          return-object
          item-value="id"
          label="Seleccione el proveedor"
          :search-input.sync="search"
          outlined
          @change="setInstitucion(institucion)"
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersect"></div>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col md="12" cols="12">
      <v-file-input
          v-model="pdf"
          label="PDF"
          placeholder="Seleccione un PDF"
          :error-messages="pdfErrors"
          @blur="$v.pdf.$touch()"
          accept="application/pdf"
          outlined
          class="mt-2"
      />
    </v-col>
    <v-col md="12" cols="12">
      <v-text-field
          label="Digite el número de teléfono del proveedor"
          outlined
          v-model="telefono"
          v-mask="'####-####'"
          :rules="[rules.required, rules.telefono]"
      ></v-text-field>
    </v-col>
    <v-col md="6" cols="6">
      <v-text-field
          ref="firmante"
          label="Ingrese el nombre del firmante oferente"
          v-model="firmante"
          outlined
          @input="$v.firmante.$touch()"
          :error-messages="firmanteErrors"
      ></v-text-field>
      <v-btn @click="sumarFirmante">Agregar firmante</v-btn>
    </v-col>
    <v-col md="6" cols="6">
      <v-text-field
          ref="firmanteDinac"
          label="Ingrese el nombre del firmante DINAC"
          v-model="firmanteDinac"
          outlined
          @input="$v.firmanteDinac.$touch()"
          :error-messages="firmanteDinacErrors"
      ></v-text-field>
      <v-btn @click="sumarFirmanteDinac">Agregar firmante</v-btn>
    </v-col>
    <listadoFirmantes/>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import listadoFirmantes from "./cmConvenioFirmantesListado.vue"
import { Validator } from '@/utils/form-validation.js';

function validarNumero(value) {
  if (value) {
    if (
        value.charAt(0) == "2" ||
        value.charAt(0) == "6" ||
        value.charAt(0) == "7"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export default {
  name: "CMConvenioInstitucionFirmantes",
  components: {
    listadoFirmantes
  },
  data: () => ({
    firmante: null,
    firmanteDinac: null,
    firmante_id_memoria: null,
    firmante_id_memoria_dinac: null,
    institucion: null,
    listado_instituciones: [],
    page: 1,
    nombre: null,
    telefono: null,
    pdf: null,
    rules: {
      required: value => !!value || 'Este es un campo requerido',
      telefono: value => (validarNumero(value) && value?.length === 9) || 'Ingrese un número de teléfono válido',
    }
  }),
  validations: {
    firmante: { required },
    firmanteDinac: { required },
    pdf: { required },
  },
  watch: {
    getFirmanteEdit(valor) {
      if (valor) {
        this.firmante = valor.nombre
        this.firmante_id_memoria = valor?.id_memoria
        this.$refs.firmante.focus()
      }
    },
    getFirmanteDinacEdit(valor) {
      if (valor) {
        this.firmanteDinac = valor.nombre
        this.firmante_id_memoria_dinac = valor?.id_memoria
        this.$refs.firmanteDinac.focus()
      }
    },
    telefono(valor) {
      if (valor && this.getTelefono !== valor) {
        this.setTelefono(valor)
      }
    },
    pdf(value) {
      if (!value) return;
      
      this.setPdf(value);
    },
  },
  computed: {
    search: {
      get() {
        return this.nombre
      },
      async set(value) {
        if (this.nombre === value) return;

        this.nombre = value
        this.page = 1
        await this.cargarTextInput()
      }
    },
    ...mapGetters(
        "cmConvenioStore",
        ["getFirmantes", "getFirmanteEdit", "getInstitucion", "getFirmantesDinac", "getFirmanteDinacEdit", "getTelefono", "getPdf"]
    ),
    listado() {
      return this.getConvenio.regiones
    },
    firmanteErrors() {
      return new Validator(this.$v.firmante).detect().getResult();
    },
    firmanteDinacErrors() {
      return new Validator(this.$v.firmanteDinac).detect().getResult();
    },
    pdfErrors() {
      return new Validator(this.$v.pdf).detect().getResult();
    },
  },
  methods: {
    ...mapActions(
        "cmConvenioStore",
        ["setInstitucion", "agregarFirmante", "editarFirmante", "agregarFirmanteDinac", "editarFirmanteDinac", "setTelefono", "setPdf"]
    ),
    sumarFirmante() {
      this.$v.firmante.$touch();
      if (!this.$v.firmante.$invalid) {
        this.agregarFirmante({
          id_memoria: this.firmante_id_memoria ?? Date.now(),
          nombre: this.firmante
        })
        this.firmante = null
        this.firmante_id_memoria = null
        this.editarFirmante(null)
        this.$v.$reset();
      }
    },
    sumarFirmanteDinac() {
      this.$v.firmanteDinac.$touch();
      if (!this.$v.firmanteDinac.$invalid) {
        this.agregarFirmanteDinac({
          id_memoria: this.firmante_id_memoria_dinac ?? Date.now(),
          nombre: this.firmanteDinac
        })
        this.firmanteDinac = null
        this.firmante_id_memoria_dinac = null
        this.editarFirmanteDinac(null)
        this.$v.$reset();
      }
    },
    async cargarInstituciones() {
      const {data, status} = await this.services.cmConvenioMarco.convenioProveedores({
        page: this.page,
        nombre: this.nombre
      });
      return {
        data,
        status
      }
    },
    validar() {
      return this.getFirmantes.length > 0 && this.getInstitucion?.id && this.getFirmantesDinac.length > 0 && this.getPdf;
    },
    async cargarTextInput() {
      this.page = 1
      const {data, status} = await this.cargarInstituciones();
      if (status === 200 && data.data.length > 0) {
        this.listado_instituciones = data.data
      }
    },
    async cargarScroll() {
      this.page++
      const {data, status} = await this.cargarInstituciones();
      if (status === 200 && data.data.length > 0) {
        this.listado_instituciones = [...this.listado_instituciones, ...data.data]
      }
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.cargarScroll();
      }
    }
  },
  created() {
    this.cargarTextInput()
  }
};
</script>
