<template>
  <Fragment>
    <v-row>
      <v-col md="4" cols="12">
        <v-autocomplete return-object v-model="ofertaConvenio.CMProducto" label="Seleccione el producto"
                        :items="productos"
                        outlined
                        @input="$v.ofertaConvenio.CMProducto.$touch()"
                        :error-messages="productoErrors"
                        item-text="nombre"
        ></v-autocomplete>
      </v-col>
      <v-col md="4" cols="12">
        <v-text-field outlined label="Digite la cantidad" v-model="ofertaConvenio.cantidad_ingreso"
                      @input="$v.ofertaConvenio.cantidad_ingreso.$touch()"
                      :error-messages="cantidadError"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="12">
        <v-text-field label="Digite el precio"
                      outlined
                      v-model="ofertaConvenio.precio"
                      @input="$v.ofertaConvenio.precio.$touch()"
                      :error-messages="precioError"
        ></v-text-field>
      </v-col>
      <v-col md="12" cols="12">
        <listadoAtributos ref="atributos" v-if="getListadoAtributos.length > 0"/>
      </v-col>
      <v-col md="12" cols="12">
        <v-btn color="primary" @click="agregar">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" cols="12">
        <listado v-if="getListadoOfertas.length > 0" :actions="actions"/>
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import {numeric, required, decimal} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapState} from "vuex";
import listadoAtributos from './cmConvenioOfertaListadoAtributos.vue'
import listado from './cmConvenioOfertasListado.vue'
import {Validator} from '@/utils/form-validation.js';

export default {
  name: "CMConvenioOferta",
  components: {
    listadoAtributos,
    listado
  },
  data: () => ({
    listado: [],
    productos: [],
    atributos: [],
    ofertaConvenio: {
      CMProducto: null,
      cantidad_ingreso: null,
      precio: null,
    },
    producto_id_memoria: null,
  }),
  validations: {
    ofertaConvenio: {
      CMProducto: {required},
      precio: {required, decimal},
      cantidad_ingreso: {required, numeric},
    },
  },
  computed: {
    ...mapGetters("cmConvenioStore", ["getListadoAtributos", "getListadoOfertas", "getOfertaEdit"]),
    ...mapState("cmConvenioStore", ["tipo_convenio"]),
    precioError() {
      return new Validator(this.$v.ofertaConvenio.precio).detect().getResult();
    },
    cantidadError() {
      return new Validator(this.$v.ofertaConvenio.cantidad_ingreso).detect().getResult();
    },
    productoErrors() {
      return new Validator(this.$v.ofertaConvenio.CMProducto).detect().getResult();
    },
  },
  watch: {
    'ofertaConvenio.CMProducto': async function (producto) {
      if (producto && this.producto_id_memoria === null) await this.setListadoAtributosInicial(producto?.id);
    },
    getOfertaEdit(valor) {
      if (valor) {
        this.ofertaConvenio = valor
        this.producto_id_memoria = valor?.id_memoria
        this.setListadoAtributosLocal(valor?.atributos)
      }
    },
    tipo_convenio: {
      handler () {
        this.obtenerProductos();
      },
      immediate: true,
    }
  },
  props: {
    actions: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions("cmConvenioStore", ["agregarOferta", "setListadoAtributosInicial", "setListadoAtributosLocal"]),
    limpiar() {
      this.ofertaConvenio = {
        CMProducto: null,
        cantidad_ingreso: null,
        precio: null,
      }
      this.producto_id_memoria = null
      this.setListadoAtributosLocal([])
      this.$v.$reset();
    },
    validar() {
      return this.getListadoOfertas.length > 0
    },
    agregar() {
      this.$v.$touch();
      if (this.getListadoAtributos.length > 0 && !this.$refs.atributos?.validarValores()){
        this.temporalAlert({
          show: true,
          message: "Por favor, complete el valor para todos los atributos",
          type: "error",
        });
        return
      }

      if (!this.$v.$invalid) {
        this.ofertaConvenio.atributos = this.getListadoAtributos
        this.agregarOferta({...this.ofertaConvenio, id_memoria: this.producto_id_memoria ?? Date.now()})
        this.limpiar()
      }
    },
    async obtenerProductos() {
      const {data, status} = await this.services.cmProducto.listProductos({
        pagination: false,
        id_tipo_producto:  this.tipo_convenio.id,
      })
      if (status === 200) {
        this.productos = data.data
      }
    },
  },

};
</script>
