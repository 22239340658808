<template>
  <Fragment>
    <v-dialog v-model="isOpen" persistent fullscreen>
      <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
        <v-container style="max-height: 90vh;  position: relative;">
          <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
            <v-btn
              style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
              class="px-4 py-4" @click.stop="cerrarModal" text rounded>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col md="12" cols="12" class="d-flex flex-column align-center">
              <v-row>
                <v-col md="12" cols="12" class="d-flex flex-row align-center">
                  <h3 class="mr-2">Tiempo en horas</h3>
                  <v-switch color="secondary" inset v-model="conversorDiasAHoras"></v-switch>
                  <h3 class="mr-2">Tiempo en días</h3>
                </v-col>
              </v-row>
            </v-col>
            <v-row>
              <v-col md="3" cols="12">
                <v-autocomplete return-object v-model="regionConvenio.CMRegion" label="Seleccione la región"
                  :items="regiones" outlined @input="$v.regionConvenio.CMRegion.$touch()" :error-messages="regionErrors"
                  item-text="nombre"></v-autocomplete>
              </v-col>

              <v-col md="3" cols="12">
                <v-text-field outlined label="Tiempo de respuesta" v-model="regionConvenio.tiempo_respuesta"
                  @input="$v.regionConvenio.tiempo_respuesta.$touch()"
                  :error-messages="tiempoRespuestaError"></v-text-field>
              </v-col>

              <v-col md="3" cols="12">
                <v-text-field label="Tiempo de respuesta en emergencia" outlined
                  v-model="regionConvenio.tiempo_respuesta_eme" @input="$v.regionConvenio.tiempo_respuesta_eme.$touch()"
                  :error-messages="tiempoRespuestaEmeError"></v-text-field>
              </v-col>

              <v-col md="3" cols="12">
                <v-btn color="primary" @click="agregar">Agregar</v-btn>
              </v-col>
            </v-row>
          </v-row>
          <listado :actions="actions" />
        </v-container>
      </div>
    </v-dialog>
  </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';
import listado from './cmConvenioRegionesListado.vue'
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { Validator } from '@/utils/form-validation.js';

export default {
  name: "CMConvenioRegiones",
  components: {
    listado,
    Fragment
  },
  props: {
    isOpen: { type: Boolean, },
    isLoading: { type: Boolean },
    oferta: { type: Object },
  },
  data: () => ({
    e1: 1,
    listado: [],
    regiones: [],
    regionConvenio: {
      CMRegion: null,
      tiempo_respuesta: null,
      tiempo_respuesta_eme: null,
    },
    region_id_memoria: null,
    conversorDiasAHoras: false  //por defectoe l switch es false; es decir horas; de ser true es días
  }),
  validations: {
    regionConvenio: {
      CMRegion: { required },
      tiempo_respuesta: { required, numeric },
      tiempo_respuesta_eme: { required, numeric },
    },
  },
  watch: {
    getRegionEdit(valor) {
      if (valor) {
        this.regionConvenio = valor
        this.region_id_memoria = valor?.id_memoria
      }
    }
  },
  computed: {
    ...mapGetters("cmConvenioStore", ["getListadoRegiones", "getRegionEdit"]),
    tiempoRespuestaError() {
      return new Validator(this.$v.regionConvenio.tiempo_respuesta).detect().getResult();
    },
    tiempoRespuestaEmeError() {
      return new Validator(this.$v.regionConvenio.tiempo_respuesta_eme).detect().getResult();
    },
    regionErrors() {
      return new Validator(this.$v.regionConvenio.CMRegion).detect().getResult();
    }
  },
  props: {
    actions: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions("cmConvenioStore", ["agregarRegion"]),
    agregar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.convertirDiasAHoras();
        this.listado.push(this.regionConvenio)
        this.agregarRegion({
          ...this.regionConvenio,
        })
        this.limpiar()
      }
    },
    limpiar() {
      this.regionConvenio = {
        region: null,
        tiempo_respuesta: null,
        tiempo_respuesta_eme: null,
      }
      this.$v.$reset();
    },
    async cargarRegiones() {
      const { status, data } =
        await this.services.cmRegiones.listRegion({
          pagination: false,
        });
      if (status === 200) {
        this.regiones = data.data;
      }
    },
    validar() {
      return this.getListadoRegiones?.length > 0
    },
    convertirDiasAHoras() {
      if (this.conversorDiasAHoras) {
        // Si el switch está activado, realiza la conversión de días a horas
        if (this.regionConvenio.tiempo_respuesta !== null) {
          this.regionConvenio.tiempo_respuesta *= 24; // Convierte días a horas
        }
        if (this.regionConvenio.tiempo_respuesta_eme !== null) {
          this.regionConvenio.tiempo_respuesta_eme *= 24; // Convierte días a horas
        }
      }
    },

  },
  async created() {
    await this.cargarRegiones();
  }
};
</script>
