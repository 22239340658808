<template>
  <v-row>
    <v-col md="12" cols="12">
      <h1>Atributos</h1>
      <v-form ref="form">
        <DataTableComponent :tiene_paginacion="false" :headers="headers" :items="getListadoAtributos">
          <template v-slot:[`item.valor`]="{ item }">
            <v-text-field
                v-model="item.valor"
                :rules="[rules.required]"
            ></v-text-field>
          </template>
          <template v-slot:[`item.unidad`]="{ item }">
            {{ item.unidad ?? 'Sin unidad' }}
          </template>
        </DataTableComponent>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "CMConvenioOfertaAtributosListado",
  components: {DataTableComponent},
  data: () => ({
    headers: [
      {
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Unidad',
        value: 'unidad'
      },
      {
        text: 'Valor',
        value: 'valor'
      }
    ],
    rules: {
      required: value => !!value || 'Este campo es requerido',
      number: value => !!(/^\d*\.?\d*$/.test(String(value)) && value > 0) || 'Este campo debe ser numérico',
    }
  }),
  computed: {
    ...mapGetters("cmConvenioStore", ["getListadoAtributos"]),
    listado() {
      return this.getConvenio.regiones
    }
  },
  methods: {
    validarValores() {
      if (!this.$refs.form.validate()) return false;
      for (const item of this.getListadoAtributos) {
        const [, , , , valorKey] = Object.keys(item);
        if (!valorKey || item.valor === null || item.valor === '') {
          return false
        }
      }
      return true
    }
  },
};
</script>
