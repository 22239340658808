<template>
  <v-row>
    <v-col md="6" cols="6">
      <v-text-field
          ref="direccion"
          label="Ingrese la dirección"
          v-model="direccion"
          outlined
          @input="$v.direccion.$touch()"
          :error-messages="direccionErrors"
      ></v-text-field>
    </v-col>
    <v-col md="6" cols="6">
      <v-btn @click="sumarDireccion">Agregar direccion</v-btn>
    </v-col>
    <v-col md="12" cols="12">
      <listado v-if="getDirecciones.length>0"/>
    </v-col>
  </v-row>
</template>
<script>
import listado from './cmConvenioDireccionesListado.vue'
import {mapGetters, mapActions} from "vuex";
import { required } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';

export default {
  name: "CMConvenioInstitucionDirecciones",
  components: {
    listado
  },
  data: () => ({
    direccion: null,
    direccion_id_memoria: null,
  }),
  validations: {
    direccion: {
      required
    }
  },
  methods: {
    ...mapActions('cmConvenioStore', ['agregarDireccion']),
    sumarDireccion() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.agregarDireccion({
        direccion: this.direccion,
        id_memoria: this.direccion_id_memoria ?? Date. now()
      })
      this.direccion = null
      this.direccion_id_memoria = null
      this.$v.$reset()
    },
    validar(){
      return this.getDirecciones.length > 0
    }
  },
  watch: {
    getDireccionEdit(valor) {
      if (valor) {
        this.direccion = valor.direccion
        this.direccion_id_memoria = valor?.id_memoria
        this.$refs.direccion.focus()
      }
    }
  },
  computed: {
    direccionErrors() {
      return new Validator(this.$v.direccion).detect().getResult();
    },
    ...mapGetters('cmConvenioStore', ['getDireccionEdit', 'getDirecciones'])
  },
}
</script>
