<template>
  <Fragment>
    <v-menu
        max-width="290px"
        min-width="auto"
        offset-y
        transition="scale-transition"
        v-model="menuInicio"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            @click:clear="temp_fecha_convenio = null"
            :value="fechaFormatted(temp_fecha_convenio)"
            append-icon="mdi-calendar"
            clearable
            :error-messages="fechaErrors"
            @blur="$v.temp_fecha_convenio.$touch()"
            label="Fecha del convenio *"
            outlined
            placeholder="Seleccione una fecha"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="temp_fecha_convenio"
          no-title
          show-current
          :show-week="false"
          locale="es-Es"
          header-color="#404a7a"
          style="margin-right: 40px; margin-top: 15px"
      />
    </v-menu>
    <v-autocomplete 
      :items="listado_tipos_convenio" 
      item-text="nombre" 
      item-value="id" 
      v-model="temp_tipo_convenio"
      @input="($v.temp_tipo_convenio.$touch()), (setTipoConvenio(temp_tipo_convenio))"
      :error-messages="tipoConvenioErrors"
      return-object
      outlined
      placeholder="Tipo de convenio"
    />
  </Fragment>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { mapActions } from "vuex";
import { Validator } from '@/utils/form-validation.js';

export default {
  name: "CMConvenioInicio",
  data: () => ({
    listado_tipos_convenio: [],
    temp_tipo_convenio: null,
    temp_fecha_convenio: null,
    menuInicio: false,
  }),
  watch: {
    temp_fecha_convenio: function (val) {
      this.setFechaConvenio(this.fechaFormatted(val));
    },
  },
  computed: {
    tipoConvenioErrors() {
      return new Validator(this.$v.temp_tipo_convenio).detect().getResult();
    },
    fechaErrors() {
      return new Validator(this.$v.temp_fecha_convenio).detect().getResult();
    },
  },
  methods: {
    ...mapActions("cmConvenioStore", ["initFechaConvenio", "setFechaConvenio", "setTipoConvenio", "setValidoInicio"]),
    fechaFormatted(fecha) {
      if (fecha ) {
        return moment(fecha).format("DD-MM-YYYY")
      } else {
        return ""
      }
    },
    validar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        return true;
      }
      return false;
    }
  },
  validations: {
    temp_tipo_convenio: {required},
    temp_fecha_convenio: {required}
  },
  async created() {
    const {data, status} = await this.services.cmTipoconvenio.listTiposConvenio()
    if (status === 200) this.listado_tipos_convenio = data.data
  }
}
</script>
