<script>
import cmRegion from './components/cmConvenioRegiones.vue'
import cmOferta from './components/cmConvenioOferta.vue'
import listadoRegiones from './components/cmConvenioRegionesListado.vue'
import institucionFirmantes from './components/cmConvenioInstitucionFirmantes.vue'
import inicio from './components/cmConvenioInicio.vue'
import listadoOfertas from './components/cmConvenioOfertasListado.vue'
import listadoFirmantes from "./components/cmConvenioFirmantesListado.vue"
import inicioRead from './components/cmConvenioInicioRead.vue'
import direcciones from './components/cmConvenioDirecciones.vue'
import listadoDirecciones from './components/cmConvenioDireccionesListado.vue'
import institucionRead from './components/cmConvenioInstitucionRead.vue'
import {mapGetters, mapActions} from "vuex";
import { convertToFormData } from '@/utils/data';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';

export default {
  name: "CMConvenio",
  data: () => ({
    e1: 1,
    creation: createLoadable(null),
  }),
  components: {
    cmRegion,
    cmOferta,
    listadoRegiones,
    institucionFirmantes,
    inicio,
    listadoOfertas,
    listadoFirmantes,
    inicioRead,
    direcciones,
    listadoDirecciones,
    institucionRead,
  },
  computed: {
    ...mapGetters(
        "cmConvenioStore",
        ["getListadoRegiones", "getPdf", "getListadoOfertas", "getFirmantes", "getInstitucion", "getTipoConvenio", "getFechaConvenio", "getDirecciones", "getFirmantesDinac", "getTelefono"]),
  },
  methods: {
    ...mapActions("cmConvenioStore", ["resetState"]),
    async finalizar() {
      toggleLoadable(this.creation);

      const convenioData = {
        tipo_convenio: this.getTipoConvenio,
        fecha_convenio: this.getFechaConvenio,
        institucion: this.getInstitucion,
        regiones: this.getListadoRegiones,
        ofertas: this.getListadoOfertas,
        firmantes: {
          firmantes_oferente: this.getFirmantes,
          firmantes_dinac: this.getFirmantesDinac,
        },
        direcciones: this.getDirecciones.map(({ direccion }) => ({ direccion })),
        telefono: this.getTelefono,
        pdf: this.getPdf,
      };

      const { data } = await this.services.cmConvenioMarco.guardarConvenio(convertToFormData(convenioData));

      setLoadableResponse(this.creation, data);
      if (!isResponseSuccesful(data)) return;

      this.resetState();
      this.enviarAlerta(data.message, 'success');
      this.$router.push({name: 'cm-list-convenio'});
    },
    convenioSeleccionado() {
      this.$refs.inicio.validar() ? this.e1++ : this.enviarAlerta();
    },
    convenioInstitucionFirmantes() {
      this.$refs.institucionFirmantes.validar() ? this.e1++ : this.enviarAlerta();
    },
    convenioRegiones() {
      this.$refs.listadoRegiones.validar() ? this.e1++ : this.enviarAlerta();
    },
    convenioOferta() {
      this.$refs.ofertas.validar() ? this.e1++ : this.enviarAlerta();
    },
    convenioDirecciones() {
      this.$refs.direcciones.validar() ? this.e1++ : this.enviarAlerta();
    },
    ubicaciones() {
      this.getTipoConvenio?.id === 2 ? this.convenioDirecciones() : this.convenioRegiones()
    },
    enviarAlerta(message = 'Por favor, completar todos los campos', type = 'error') {
      this.temporalAlert({
        show: true,
        message,
        type,
      });
    }
  },
};
</script>
<template>
  <section>
    <v-container>
      <p class="text-h5 secondary--text">Creación del convenio</p>
      <v-stepper alt-labels v-model="e1">
        <v-stepper-header>
          <v-stepper-step
              :complete="e1 > 1"
              step="1"
          >
            <span class="text-wrap">
            Tipo y fecha
            </span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              :complete="e1 > 2"
              step="2"
          >
            Proveedor
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              :complete="e1 > 3"
              step="3"
          >
            Ubicaciones
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              :complete="e1 > 4"
              step="4"
          >
            Ofertas
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              :complete="e1 > 5"
              step="5"
          >
            Vista de resumen
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
                class="mb-12"
            >
              <v-card-title>Seleccione el tipo de convenio</v-card-title>
              <v-card-text>
                <inicio ref="inicio"/>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="convenioSeleccionado"
                >
                  Siguiente
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card
                class="mb-12"
            >
              <v-card-title>Seleccione el proveedor e ingrese firmantes</v-card-title>
              <v-card-text v-if="e1===2">
                <institucionFirmantes ref="institucionFirmantes"/>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="convenioInstitucionFirmantes"
                >
                  Siguiente
                </v-btn>
                <v-btn text @click="e1--">
                  Retroceder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card
                class="mb-12"
            >
              <v-card-title>Ubicaciones</v-card-title>
              <v-card-text v-if="e1===3">
                <direcciones ref="direcciones" v-if="getTipoConvenio?.id===2"/>
                <cm-region ref="listadoRegiones" v-else/>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="ubicaciones"
                >
                  Siguiente
                </v-btn>
                <v-btn text @click="e1--">
                  Retroceder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card
                class="mb-12"
            >
              <v-card-title>Ofertas</v-card-title>
              <v-card-text v-if="e1===4">
                <cm-oferta ref="ofertas"/>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="convenioOferta"
                >
                  Siguiente
                </v-btn>
                <v-btn text @click="e1--">
                  Retroceder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-card
                class="mb-12"
            >
              <v-card-title>Vista de resumen</v-card-title>
              <v-card-text v-if="e1===5">
                <institucionRead/>
                <inicioRead/>
                <listadoFirmantes :actions="false"/>
                <listadoDirecciones :actions="false" v-if="getTipoConvenio?.id===2"/>
                <listadoRegiones :actions="false" v-else/>
                <listadoOfertas :actions="false"/>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    :disabled="creation.isLoading"
                    @click="finalizar"
                >
                  Enviar convenio
                </v-btn>
                <v-btn text @click="e1--">
                  Retroceder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </section>
</template>
