<template>
    <v-container>
        <v-card>
            <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Reportería convenio marco</h2>
            </div>
            <v-divider />
            
            <div class="px-4 py-4" style="position: relative;">
                <v-expansion-panels class="mt-4" v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-icon>mdi-filter-settings</v-icon>
                                <span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
                            </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                            <v-divider />
                            <v-row align="center" justify="center" class="mx-4 my-4">
                                <v-col cols="12" md="6" lg="3">
                                    <v-select 
                                        v-model="filtros.tipo_convenio"
                                        :items="tiposProductos.data"
                                        :loading="tiposProductos.isLoading"
                                        :disabled="productosSolicitud.isLoading || productos.isLoading || estadosNoDevolucion.isLoading"
                                        placeholder="Seleccione un tipo de convenio" 
                                        label="Tipo de convenio" 
                                        item-text="nombre"
                                        item-value="id"
                                        outlined
                                        hide-details
                                        clearable
                                    />
                                </v-col>
            
                                <v-col cols="12" md="6" lg="3">
                                    <v-select 
                                        v-model="filtros.familia"
                                        :items="familias.data"
                                        item-text="nombre"
                                        item-value="id"
                                        hide-details
                                        clearable
                                        outlined
                                        placeholder="Seleccione un convenio"
                                        label="Convenio"
                                        :loading="familias.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        class="flex-grow-1 flex-shrink-1" 
                                        style="min-width: 200px;"
                                    />
                                </v-col>
                    
                                <v-col cols="12" md="6" lg="3">
                                    <v-select 
                                        v-model="filtros.estado"
                                        :items="estadosNoDevolucion.data"
                                        :loading="estadosNoDevolucion.isLoading"
                                        item-text="nombre"
                                        item-value="id"
                                        placeholder="Seleccione un estado" 
                                        label="Estado" 
                                        outlined
                                        hide-details
                                        clearable
                                    />
                                </v-col>
                                
                                <v-col cols="12" md="6" lg="3">
                                    <ProgresiveLoadAutocomplete
                                        v-model="filtros.producto"
                                        :pageable="productos"
                                        :loading="productos.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        class="flex-grow-1 flex-shrink-1" 
                                        style="min-width: 200px;"
                                        label="Producto"
                                        placeholder="Seleccione un producto"
                                        itemText="nombre"
                                        itemValue="id"
                                        hide-details
                                        @search="buscarProducto"
                                        @load-more="cargarMasProductos"
                                        clearable
                                    />
                                </v-col>
                    
                                <v-col cols="12" md="6" lg="3">
                                    <ProgresiveLoadAutocomplete
                                        v-model="filtros.institucion"
                                        :pageable="instituciones"
                                        :loading="instituciones.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        class="flex-grow-1 flex-shrink-1" 
                                        style="min-width: 200px;"
                                        label="Institución"
                                        placeholder="Seleccione una institución"
                                        itemText="nombre"
                                        itemValue="id"
                                        hide-details
                                        @search="buscarInstitucion"
                                        @load-more="cargarMasInstituciones"
                                        clearable
                                    />
                                </v-col>
                    
                                <v-col cols="12" md="6" lg="3">
                                    <ProgresiveLoadAutocomplete
                                        v-model="filtros.proveedor"
                                        :pageable="proveedores"
                                        :loading="proveedores.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        class="flex-grow-1 flex-shrink-1" 
                                        style="min-width: 200px;"
                                        label="Proveedor"
                                        placeholder="Seleccione un proveedor"
                                        itemText="nombre_comercial"
                                        itemValue="id"
                                        hide-details
                                        @search="buscarProveedor"
                                        @load-more="cargarMasProveedores"
                                        clearable
                                    />
                                </v-col>
            
                                <v-col cols="12" md="6" lg="3">
                                    <v-select 
                                        v-model="filtros.anio"
                                        outlined 
                                        hide-details 
                                        :items="aniosFiscales.data"
                                        :loading="aniosFiscales.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        item-value="id"
                                        item-text="anio"
                                        label="Año"
                                        placeholder="Seleccione un año"
                                        clearable
                                    />
                                </v-col>
            
                                <v-col cols="12" md="6" lg="3">
                                    <ProgresiveLoadAutocomplete
                                        v-model="filtros.proceso_compra"
                                        :transform-item="mapearProcesoCompra"
                                        :pageable="procesosCompra"
                                        :loading="procesosCompra.isLoading"
                                        :disabled="productosSolicitud.isLoading"
                                        class="flex-grow-1 flex-shrink-1" 
                                        style="min-width: 200px;"
                                        label="Proceso de compra"
                                        placeholder="Seleccione un proceso de compra"
                                        itemText="nombre_completo"
                                        itemValue="id"
                                        hide-details
                                        @search="buscarProcesoCompra"
                                        @load-more="cargarMasProcesosCompra"
                                        clearable
                                    />
                                </v-col>
                    
                                <v-col cols="12" md="6" lg="3">
                                    <v-btn 
                                        color="primary" 
                                        style="width: 100%"
                                        x-large
                                        :disabled="productosSolicitud.isLoading"
                                        @click.stop="buscarProductosSolicitud"
                                    >
                                        Buscar
                                    </v-btn>
                                </v-col>
                    
                                <v-col cols="12" md="6" lg="3">
                                    <v-btn 
                                        color="primary" 
                                        style="width: 100%"
                                        x-large
                                        outlined
                                        :disabled="productosSolicitud.isLoading"
                                        @click.stop="restablecerFiltros"
                                    >
                                        Limpiar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-row class="mt-4">
                    <v-col cols="12" lg="6" xl="4">
                        <Estadisticas :estadisticas="estadisticas" />
                    </v-col>
                    <v-col cols="12" lg="6" xl="4">
                        <TopProductos />
                    </v-col>
                    <v-col cols="12" xl="4">
                        <TotalesConvenio />
                    </v-col>
                    <v-col cols="12">
                        <MontosPorAnio />
                    </v-col>
                </v-row>

                <DataTableComponent 
                    :headers="headers"
                    :items="productosSolicitud.data"
                    :show_loading="productosSolicitud.isLoading"
                    :total_registros="total"
                    v-models:pagina="paginacionProductosSolicitud.pagina"
                    v-models:select="paginacionProductosSolicitud.registrosPorPagina"
                    class="mt-8"
                    dense
                    no-gutters
                    @paginar="manejarPaginacionProductosSolicitud"
                >
                    <template v-slot:[`item.subtotal`]="{ item }">
                        <span class="font-weight-bold">
                            ${{
                                Intl.NumberFormat("en-US", {
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                }).format(item.movimiento.monto ?? item.subtotal)
                            }}
                        </span>
                    </template>
                    <template v-slot:[`item.estado`]="{ item }">
                        <v-chip 
                            label 
                            :color="obtenerColorFondoEstado(item.secuencia)" 
                            :text-color="obtenerColorTextEstado(item.secuencia)"
                        >
                            {{ item.estado.nombre }}
                        </v-chip>
                    </template>
        
                    <template v-slot:[`item.tipo_convenio`]="{ item }">
                        <v-chip label>
                            {{ item.producto.tipo_producto.nombre }}
                        </v-chip>
                    </template>
                    <template v-slot:item.fecha_compra="{ item }">
                        {{ formatDate(item.solicitud.fecha_autorizacion) }}
                    </template>
                        
                    <template v-slot:item.detalles="{ item }">
                        <v-btn icon @click.stop="abrirModalDetallesMovimiento(item)">
                            <v-icon color="secondary">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </DataTableComponent>
            </div>
        </v-card>

        <ModalDetallesMovimientoCm 
            :is-open.sync="modalDetallesMovimientoAbierta"
            :movimiento="movimientoActivo"
        />
    </v-container>
</template>
<script>
import ModalDetallesMovimientoCm from './components/ModalDetallesMovimientoCm.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ProgresiveLoadAutocomplete } from '@/components/utils';
import TotalesConvenio from './components/TotalesConvenio.vue';
import MontosPorAnio from './components/MontosPorAnio.vue';
import TopProductos from './components/TopProductos.vue';
import { NavButton } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import Estadisticas from './Estadisticas.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    components: { 
        NavButton, 
        DataTableComponent, 
        ProgresiveLoadAutocomplete, 
        Estadisticas, 
        ModalDetallesMovimientoCm, 
        TopProductos, 
        TotalesConvenio,
        MontosPorAnio,
    },
    data: () => ({
        panel: [0],
        headers: [
            { align: 'center', sortable: false, text: 'Institución solicitante', value: 'institucion.nombre' },
            { align: 'center', sortable: false, text: 'Tipo de convenio', value: 'tipo_convenio' },
            { align: 'center', sortable: false, text: 'Nombre de convenio', value: 'familia.nombre' },
            { align: 'center', sortable: false, text: 'Producto', value: 'producto.nombre' },
            { align: 'center', sortable: false, text: 'Proveedor', value: 'Proveedor.nombre_comercial' },
            { align: 'center', sortable: false, text: 'Fecha de compra', value: 'fecha_compra' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            { align: 'center', sortable: false, text: 'Subtotal', value: 'subtotal' },
            { align: 'center', sortable: false, text: 'Detalles', value: 'detalles' },
        ],
        // Detalles
        modalDetallesMovimientoAbierta: false,
        movimientoActivo: null,
    }),
    computed: {
        ...mapState("cmReporteria", [
            "tiposProductos",
            "productosSolicitud", 
            "productos", 
            "estadosNoDevolucion", 
            "filtros", 
            "familias", 
            "instituciones", 
            "proveedores",
            "aniosFiscales",
            "procesosCompra",
            "estadisticas",
            "paginacionProductosSolicitud",
        ]),
        total() {
            return this.productosSolicitud.pagination.total_rows;
        },
        tipoConvenioFiltro() {
            return this.filtros.tipo_convenio;
        },
    },
    methods: {
        ...mapActions("cmReporteria", [
            "cargarInstituciones", 
            "cargarProveedores", 
            "cargarFamilias", 
            "cargarTiposProductos", 
            "buscarProducto", 
            "buscarProveedor",
            "cargarMasProveedores",
            "cargarMasInstituciones",
            "buscarInstitucion",
            "cargarMasProcesosCompra",
            "buscarProcesoCompra",
            "cargarMasProductos",
            "cargarProductos",
            "cargarEstadosNoDevolucion",
            "cargarProductosSolicitud",
            "cargarEstadisticas",
            "cargarAniosFiscales",
            "cargarProcesosCompra",
            "limpiarFiltros",
            "manejarCambioTipoConvenio",
            "buscarPorFiltros",
        ]),
        ...mapMutations("cmReporteria", ["actualizarPaginacionProductosSolicitud", "resetearEstado"]),
        formatDate,
        // UI
        mapearProcesoCompra(procesoCompra) {
            return ({ ...procesoCompra, nombre_completo: `${procesoCompra.codigo_proceso} - ${procesoCompra.nombre_proceso}` });
        },
        obtenerColorFondoEstado(secuencia) {
            if (secuencia?.finaliza_exitosamente) return '#00B6F5';
            if (secuencia?.retroceso_en_flujo) return '#252B58';
        },
        obtenerColorTextEstado(secuencia) {
            if (secuencia?.finaliza_exitosamente || secuencia?.retroceso_en_flujo) return '#FFF !important';
        },
        manejarPaginacionProductosSolicitud(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.actualizarPaginacionProductosSolicitud({ page: pagina, per_page: cantidad_por_pagina });
            this.cargarProductosSolicitud();
        },
        // Detalles
        abrirModalDetallesMovimiento(movimiento) {
            this.movimientoActivo = { ...movimiento };
            this.modalDetallesMovimientoAbierta = true;
        },
        // Data principal
        buscarProductosSolicitud() {
            this.buscarPorFiltros();
        },
        restablecerFiltros() {
            this.actualizarPaginacionProductosSolicitud({ page: 1, per_page: this.paginacionProductosSolicitud.registrosPorPagina });
            this.limpiarFiltros();
        },
    },
    watch: {
        tipoConvenioFiltro() {
            this.manejarCambioTipoConvenio();
        },
    },
    created() {
        this.cargarInstituciones();
        this.cargarProveedores();
        this.cargarFamilias();
        this.cargarTiposProductos();
        this.cargarProductos();
        this.cargarEstadosNoDevolucion();
        this.cargarProductosSolicitud();
        this.cargarEstadisticas();
        this.cargarAniosFiscales();
        this.cargarProcesosCompra();
    },
    beforeDestroy() {
        this.resetearEstado();
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0px !important;
}

:deep(.v-item-group) {
    position: sticky;
    top: 0;
    z-index: 150;
}

@media (min-width: 960px) {
    :deep(.v-item-group) {
        top: 64px;
    }
}
</style>